export const fontFOptions = [
	{ label: 'Barlow', value: 'Barlow' },
	{ label: 'Bitter', value: 'Bitter' },
	{ label: 'Bodoni Moda', value: 'Bodoni Moda' },
	{ label: 'Cormorant Garamond', value: 'Cormorant Garamond' },
	{ label: 'Comic Neue', value: 'Comic Neue' },
	{ label: 'Josefin Sans', value: 'Josefin Sans' },
	{ label: 'Lora', value: 'Lora' },
	{ label: 'Nunito', value: 'Nunito' },
	{ label: 'Open Sans', value: 'Open Sans' },
	{ label: 'Poppins', value: 'Poppins' },
	{ label: 'Roboto', value: 'Roboto' },
	{ label: 'Source Sans Pro', value: 'Source Sans Pro' },
	{ label: 'Source Serif Pro', value: 'Source Serif Pro' },
	{ label: 'Work Sans', value: 'Work Sans' },
];
export const fontSOptions = [
	{ label: 'Smaller', value: '14px' },
	{ label: 'Small', value: '15px' },
	{ label: 'Normal', value: '16px' },
	{ label: 'Large', value: '17px' },
	{ label: 'Larger', value: '18px' },
];
